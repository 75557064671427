import { getDefaultState } from 'store/initialState';
import {
  STEP_EIDV,
  STEP_DVS_CONSENT,
  STEP_PROCESSING_IDV,
  STEP_PROCESSING,
  STEP_CONFIRM_CREDIT_LIMIT,
  STEP_LOAN_INFORMATION,
  STEP_AUS_POST,
  STEP_VERIFY_INCOME,
  STEP_VERIFY_INSTORE,
} from '_config';
import {
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  POITYPE_PAYSLIP_UPLOAD,
  POITYPE_DOC_UPLOAD,
  POI_CHECK_REQUIRED,
  POI_CHECK_PENDING,
  OKTA_LOGIN_AND_SEND_OTP_ERROR,
} from 'store';
import {
  PENDING,
  IDV_CHECK_REQUIRED,
  IDV_CHECK_PENDING,
  APPROVED,
  DECLINED,
  REFERRED,
  SERVICE_UNAVAILABLE,
  CONDITIONALLY_APPROVED,
  PROOF_OF_ADDRESS,
  PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING,
  QUOTE_INITIAL,
  QUOTE_DECLINE,
  APOLLO_NETWORK_ERROR,
  APOLLO_GRAPHQL_ERROR,
  CCR_REQUIRED,
  BIOMETRICS_CHECK_REQUIRED,
} from 'store/constants';
import { isFeatureOn } from 'featureToggles';

const personalDetails = {
  valid: {
    title: 'MR',
    firstName: 'Rafael',
    middleName: 'Kingsley Dom',
    lastName: 'Madams',
    dateOfBirth: {
      day: '25',
      month: '12',
      year: '1973',
    },
    hasAuDL: 'yes',
    hasMiddleName: 'yes',
    australianState: 'VIC',
    driverLicenceNumber: '1234567',
    driverLicenceCardNumber: 'AB123456',
    relationshipStatus: 'MARRIED_DEFACTO',
    numberOfDependents: '0',
    crbConsent: true,
    dvsConsent: true,
  },
};

const contactDetails = {
  valid: {
    mobileNumber: '0400000000',
    emailAddress: 'mock@email.com',
    confirmEmail: 'mock@email.com',
  },
  'test-case-approved': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_APPR_A100@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_APPR_A100@latitudefinancial.com',
  },
  'test-case-customer-acceptance': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_PCA_P01A@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_PCA_P01A@latitudefinancial.com',
  },
  'test-case-declined': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_DECL_D03X@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_DECL_D03X@latitudefinancial.com',
  },
  'test-case-referred': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_REFR_R70B@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_REFR_R70B@latitudefinancial.com',
  },
  'test-case-system-unavailable': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_SERVICE_UNAVAILABLE@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_SERVICE_UNAVAILABLE@latitudefinancial.com',
  },
  'test-case-conditionally-approved': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_CAPPR_C74B@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_CAPPR_C74B@latitudefinancial.com',
  },
  'test-case-verifier-eligibility-true': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_VFR_ELG_TRUE_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_VFR_ELG_TRUE_POI@latitudefinancial.com',
  },
  'test-case-dvs-false-eidv-pass-poi-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_DVS_FALSE_PASS_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_DVS_FALSE_PASS_POI@latitudefinancial.com',
  },
  'test-case-dvs-true-eidv-pass-poi-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_DVS_TRUE_PASS_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_DVS_TRUE_PASS_POI@latitudefinancial.com',
  },
  'test-case-eidv-passport-pass-poi-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_PASSPORT_PASS_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_PASSPORT_PASS_POI@latitudefinancial.com',
  },
  'test-case-eidv-medicare-pass-poi-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_MEDICARE_PASS_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_MEDICARE_PASS_POI@latitudefinancial.com',
  },
  'test-case-eidv-none-pass-poi-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_NONE_PASS_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_NONE_PASS_POI@latitudefinancial.com',
  },
  'test-case-eidv-required-poi-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_FAIL_AML_POI@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_FAIL_AML_POI@latitudefinancial.com',
  },
  'test-case-eidv-required': {
    mobileNumber: '0400000000',
    emailAddress: 'APPLYUI_ORIG_TEST_IDV_NONE_FAIL_AML@latitudefinancial.com',
    confirmEmail: 'APPLYUI_ORIG_TEST_IDV_NONE_FAIL_AML@latitudefinancial.com',
  },
  'error:lost-network': {
    applicationErrors: { type: OKTA_LOGIN_AND_SEND_OTP_ERROR, error: new Error('service unavailable') },
  },
  'error:service-unavailable': {
    applicationErrors: { type: '', error: new Error('service unavailable') },
  },
};

const latitudeId = {
  valid: {
    latitudeId: 'mock@email.com',
    password: '12Abc$^&',
  },
};

const verification = {
  valid: {
    verificationCode: '123456',
  },
};

const emailVerification = {
  valid: {
    verificationCode: '123456',
  },
};

const password = {
  valid: {
    password: 'Abc123',
  },
};

const otherDetails = {
  valid: {
    residentialStatus: 'HOME_OWNER',
    lengthOfCurrentResidency: {
      years: '3',
      months: '0',
    },
    isPostalSameWithResidential: 'yes',
    hasNonDomesticPreviousAddress: false,
    currentAddress: {
      useManualAddress: false,
      address: {
        addressType: 'STREET_ADDRESS',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '800',
        streetName: 'Collins',
        streetType: 'Road',
        suburb: 'Docklands',
        state: 'VIC',
        postcode: '3008',
        countryCode: 'AU',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        addressType: '',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        state: '',
        postcode: '',
        countryCode: 'AU',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        addressType: '',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        state: '',
        postcode: '',
        countryCode: 'AU',
      },
    },
  },
};
const extraDetails = {
  valid: {
    idType: 'AU_DRIVERS_LICENCE',
    licenceNumber: '123123',
    version: '453',
    expiryDate: {
      day: '01',
      month: '01',
      year: '2030',
    },
    australianState: 'VIC',
  },
};

// TODO update this with correct value
// https://di.latitudefinancial.com/jira/browse/NZP-518
const employmentDetails = {
  valid: {
    industry: 'COMPUTER_STAFF',
    employerName: 'Latitude',
    lengthOfTimeAtCurrentEmployer: {
      years: '1',
      months: '0',
    },
    lengthOfUnemployment: {
      years: '',
      months: '',
    },
    income: {
      amount: '14000',
      frequency: 'monthly',
    },
    employmentStatus: 'PERMANENT_FULL_TIME',
    hasOtherIncome: 'yes',
    otherEmploymentStatus: 'CASUAL_TEMPORARY',
    otherIncome: {
      amount: '1000',
      frequency: 'monthly',
    },
    expectedIncomeChange: 'no',
    incomeAdjustmentRequired: 'no',
    conditionalStop: 'no',
  },
};
const expensesDetails = {
  valid: {
    isExpensesShared: 'no',
    mortgageRentBoard: {
      amount: '1000',
      frequency: 'monthly',
    },
    foodGroceriesLifestyleEntertainment: {
      amount: '400',
      frequency: 'monthly',
    },
    insurance: {
      amount: '300',
      frequency: 'monthly',
    },
    utilities: {
      amount: '400',
      frequency: 'monthly',
    },
    educationAndChildcare: {
      amount: '500',
      frequency: 'monthly',
    },
    transport: {
      amount: '600',
      frequency: 'monthly',
    },
    personalAndOtherExpense: {
      amount: '700',
      frequency: 'monthly',
    },
  },
};

const additionalDebtsDetails = {
  valid: {
    hasOtherDebts: 'no',
    secondaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: 'no',
    payLumpSumWithExistingSaving: '',
  },
  'error:lost-netWork': {
    hasOtherDebts: 'no',
    secondaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: 'no',
    payLumpSumWithExistingSaving: '',
    applicationErrors: { type: APOLLO_NETWORK_ERROR, error: { networkError: {} } },
  },
  'error:service-unavailable': {
    hasOtherDebts: 'no',
    secondaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: 'no',
    payLumpSumWithExistingSaving: '',
    applicationErrors: { type: APOLLO_GRAPHQL_ERROR, error: new Error('service unavailable') },
  },
};

const confirmCreditLimit = {
  empty: {
    newCreditLimit: '',
    creditLimitOptions: null,
  },
  valid: {
    newCreditLimit: '',
    creditLimitOptions: 'acceptCreditLimit',
  },
  declined: {
    creditLimit: '',
  },
  referred: {
    creditLimit: '',
  },
  conditionallyApproved: {
    creditLimit: '',
  },
};

const preferredCreditLimitDetails = {
  valid: {
    creditLimit: '5000',
  },
  declined: {
    creditLimit: '2000',
  },
  referred: {
    creditLimit: '2000',
  },
  conditionallyApproved: {
    creditLimit: '2000',
  },
};

const acceptance = {
  valid: {
    accepted: true,
  },
};

const achDetails = {
  valid: {
    hasAch: 'yes',
    sameCurrentAddress: 'no',
    personalDetails: {
      title: 'MRS',
      firstName: 'Rachel',
      middleName: 'R',
      lastName: 'Madams',
      dateOfBirth: {
        day: '1',
        month: '8',
        year: '1975',
      },
      hasMiddleName: 'yes',
      mobileNumber: '0400000001',
      emailAddress: 'mock@email.com',
    },
    currentAddress: {
      useManualAddress: false,
      address: {
        addressType: 'STREET_ADDRESS',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '801',
        streetName: 'Collins',
        streetType: 'Road',
        suburb: 'Docklands',
        state: 'VIC',
        postcode: '3008',
        countryCode: 'AU',
      },
    },
  },
  sameAddress: {
    hasAch: 'yes',
    sameCurrentAddress: 'yes',
    personalDetails: {
      title: 'MRS',
      firstName: 'Rachel',
      middleName: 'R',
      lastName: 'Madams',
      dateOfBirth: {
        day: '1',
        month: '8',
        year: '1975',
      },
      hasMiddleName: 'yes',
      mobileNumber: '0400000001',
      emailAddress: 'mock@email.com',
    },
    currentAddress: {
      useManualAddress: false,
      address: {
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        postcode: '',
        poBoxNumber: '',
        addressType: '',
        countryCode: '',
        state: '',
      },
    },
  },
};

const complete = {
  approved: {
    overview: {
      promoCode: '',
      consentGiven: true,
      hasReadImportantDocuments: true,
      applicationReason: '',
      conditionalStop: '',
    },
    contactDetails: contactDetails.valid,
    latitudeId: latitudeId.valid,
    password: password.valid,
    verification: {
      verificationCode: '',
    },
    emailVerification: {
      verificationCode: '',
    },
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    confirmCreditLimit: confirmCreditLimit.empty,
    preferredCreditLimitDetails: preferredCreditLimitDetails.valid,
    acceptance: acceptance.valid,
    achDetails: achDetails.valid,
  },
  declined: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          consentGiven: true,
          hasReadImportantDocuments: true,
        }
      : {
          consentGiven: true,
          hasReadImportantDocuments: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    confirmCreditLimit: confirmCreditLimit.declined,
    preferredCreditLimitDetails: preferredCreditLimitDetails.declined,
  },
  timeout: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          consentGiven: true,
          hasReadImportantDocuments: true,
        }
      : {
          consentGiven: true,
          hasReadImportantDocuments: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    confirmCreditLimit: confirmCreditLimit.referred,
    preferredCreditLimitDetails: preferredCreditLimitDetails.referred,
  },
  systemUnavailable: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          consentGiven: true,
          hasReadImportantDocuments: true,
        }
      : {
          consentGiven: true,
          hasReadImportantDocuments: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    confirmCreditLimit: confirmCreditLimit.referred,
    preferredCreditLimitDetails: preferredCreditLimitDetails.referred,
  },
  referred: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          consentGiven: true,
          hasReadImportantDocuments: true,
        }
      : {
          consentGiven: true,
          hasReadImportantDocuments: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    confirmCreditLimit: confirmCreditLimit.referred,
    preferredCreditLimitDetails: preferredCreditLimitDetails.referred,
  },
  conditionallyApproved: {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          consentGiven: true,
          hasReadImportantDocuments: true,
        }
      : {
          consentGiven: true,
          hasReadImportantDocuments: true,
        },
    contactDetails: contactDetails.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    confirmCreditLimit: confirmCreditLimit.conditionallyApproved,
    preferredCreditLimitDetails: preferredCreditLimitDetails.approved,
  },
};

const eIDVConsent = {
  dvsConsent: false,
};

const eIDV = {
  Medicare: {
    idType: 'AU_MEDICARE',
    name: '{"fullName":"Rafael K Dom Madams","middleName":"K Dom"}',
    medicareCardNumber: '1234567890',
    individualReferenceNumber: '1',
    medicareCardColour: 'G',
    expiryDateGreen: {
      day: '',
      month: '3',
      year: '2050',
    },
    expiryDateNonGreen: {
      day: '',
      month: '',
      year: '',
    },
    idNumber: '',
  },
  AuPassport: {
    idType: 'AU_PASSPORT',
    idNumber: 'N1234567',
    name: '',
    medicareCardNumber: '',
    individualReferenceNumber: '',
    medicareCardColour: '',
    expiryDateGreen: {
      day: '',
      month: '',
      year: '',
    },
    expiryDateNonGreen: {
      day: '',
      month: '',
      year: '',
    },
  },
};

export const autofill = {
  overview: {
    'complete:approved': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: APPROVED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      applicationDate: new Date().toISOString(),
      accountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
      creditLimitApproved: 3000,
      applicationRef: '7323655040880',
    },
    'complete:declined': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: DECLINED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.declined,
    },
    'complete:referred': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: REFERRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.referred,
    },
    'complete:timeout': {
      activeStep: '/outcome-timeout',
      applicationSubmitted: true,
      applicationStatus: PENDING,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.timeout,
    },
    'complete:fin-table': {
      activeStep: '/outcome-fin-table',
      applicationSubmitted: true,
      applicationStatus: APPROVED,
      applicationErrors: null,
      applicationDate: new Date().toISOString(),
      accountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
      creditLimitApproved: 3000,
    },
    'complete:system-unavailable': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: SERVICE_UNAVAILABLE,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.systemUnavailable,
    },
    'complete:POI_CHECK_REQUIRED': {
      activeStep: '/verify-income',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'complete:POI_PROVISO': {
      activeStep: '/verify-ficonnect',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'complete:POI_DOC_UPLOAD': {
      activeStep: '/verify-document-upload',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'complete:POI_DOC_UPLOAD_TIMEOUT': {
      activeStep: '/outcome-timeout',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_PENDING,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.referred,
    },
    'go-to:SAVED_APPLICATION': {
      activeStep: '/saved',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'go-to:processing': {
      activeStep: '/processing',
      applicationSubmitted: true,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      acquisition: complete.approved,
    },
    'go-to:processing-biometrics': {
      activeStep: '/processing-biometrics',
      applicationSubmitted: true,
      applicationStatus: BIOMETRICS_CHECK_REQUIRED,
      applicationErrors: null,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: true,
      applicationStartDate: new Date().toISOString(),
      gemVisaAccountNumber: '5218 9435 0015 199',
      maskedMobileNumber: '044 *** 234',
      acquisition: complete.approved,
    },
    'complete:referred-upload-payslips': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: REFERRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.referred,
    },
    'complete:referred-upload-documents': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: REFERRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.referred,
    },
    'complete:conditionally-approved-poa': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-aml': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_INCOME,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-poa-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-aml-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
      creditLimitApproved: 3000,
    },
    'complete:conditionally-approved-upload-doc-poa': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-approved-upload-doc-aml': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-payslips-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-payslips-aml-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-payslips-poa-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-documents-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-documents-aml-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'complete:conditionally-upload-documents-poa-poi': {
      activeStep: '/outcome',
      applicationSubmitted: true,
      incomeVerificationType: POITYPE_DOC_UPLOAD,
      applicationStatus: CONDITIONALLY_APPROVED,
      applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
      creditLimitApproved: 3000,
      applicationRef: '1234 5678 9012 3456',
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.conditionallyApproved,
    },
    'go-to:CCR-loan-information': {
      activeStep: STEP_LOAN_INFORMATION,
      applicationStatus: CCR_REQUIRED,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      acquisition: {
        ...complete.approved,
        otherDetails: {
          ...complete.approved.otherDetails,
          residentialStatus: 'MORTGAGE',
        },
      },
      undisclosedDebts: [
        {
          id: 1,
          source: 'EQUIFAX_AUSTRALIA',
          category: 'HOME_LOAN',
          creditProvider: 'ANZ',
          currentLimit: 10000.0,
        },
        {
          id: 2,
          source: 'EQUIFAX_AUSTRALIA',
          category: 'HOME_LOAN',
          creditProvider: 'ANZ',
          currentLimit: 20000.0,
        },
        {
          id: 3,
          source: 'EQUIFAX_AUSTRALIA',
          category: 'CREDIT_CARD',
          creditProvider: 'Latitude',
          currentLimit: 3000.0,
        },
      ],
    },
    'go-to:verify-income': {
      activeStep: STEP_VERIFY_INCOME,
      applicationStatus: POI_CHECK_REQUIRED,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      acquisition: complete.approved,
    },
    'go-to:credit-limit-details-ca': {
      activeStep: STEP_CONFIRM_CREDIT_LIMIT,
      applicationStatus: QUOTE_INITIAL,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      creditLimitApproved: 7000,
      acquisition: {
        ...complete.approved,
        confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
      },
    },
    'go-to:credit-limit-details-declined': {
      activeStep: STEP_CONFIRM_CREDIT_LIMIT,
      applicationStatus: QUOTE_DECLINE,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      acquisition: {
        ...complete.referred,
        confirmReferredCreditLimit: {
          newCreditLimit: '',
        },
      },
    },
    'go-to:credit-limit-details-ca-min-limit-premiere': {
      activeStep: STEP_CONFIRM_CREDIT_LIMIT,
      applicationStatus: QUOTE_INITIAL,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      creditLimitApproved: 1000,
      acquisition: {
        ...complete.approved,
        confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
      },
    },
    'go-to:credit-limit-details-ca-min-limit-prestige': {
      activeStep: STEP_CONFIRM_CREDIT_LIMIT,
      applicationStatus: QUOTE_INITIAL,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      creditLimitApproved: 6000,
      acquisition: {
        ...complete.approved,
        confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
      },
    },
    'go-to:application-submitting': {
      activeStep: STEP_PROCESSING,
      applicationSubmitted: true,
      applicationStatus: PENDING,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
    },
    'go-to:latitude-id-introduction': {
      activeStep: '/latitude-id-introduction',
      applicationSubmitted: true,
      applicationStatus: IDV_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
      isEmailVerified: false,
      isLatitudeIdPasswordSet: false,
    },
    'go-to:latitude-id-password': {
      activeStep: '/latitude-id',
      applicationSubmitted: true,
      applicationStatus: IDV_CHECK_REQUIRED,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: false,
    },
    'go-to:eidv-consent-required': {
      activeStep: STEP_DVS_CONSENT,
      applicationSubmitted: true,
      applicationStatus: IDV_CHECK_REQUIRED,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: true,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
    },
    'go-to:eidv-eidv-pending': {
      activeStep: STEP_PROCESSING_IDV,
      applicationSubmitted: true,
      applicationStatus: IDV_CHECK_PENDING,
      applicationErrors: null,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
    },
    'go-to:eIDV': {
      activeStep: STEP_EIDV,
      applicationStatus: IDV_CHECK_REQUIRED,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: true,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      acquisition: complete.approved,
    },
    'go-to:aus-post': {
      activeStep: STEP_AUS_POST,
      applicationStatus: IDV_CHECK_REQUIRED,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: true,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      acquisition: complete.approved,
    },
    'go-to:verify-instore': {
      activeStep: STEP_VERIFY_INSTORE,
      applicationStatus: IDV_CHECK_REQUIRED,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: true,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationRef: '7014625177363',
      applicationErrors: null,
      acquisition: complete.approved,
    },
    'go-to:resume:expired': {
      activeStep: '/resume-application-expired',
      applicationStatus: IDV_CHECK_REQUIRED,
      isEmailVerified: true,
      isLatitudeIdPasswordSet: true,
      applicationSubmitted: true,
      applicationStartDate: new Date().toISOString(),
      applicationErrors: null,
      creditLimitApproved: 3000,
      acquisition: complete.approved,
    },
    'go-to:commitments': {
      activeStep: '/commitments',
      applicationSubmitted: false,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
    },
    'go-to:additional-card-holder': {
      activeStep: '/additional-card-holder',
      applicationSubmitted: false,
      applicationStartDate: new Date().toISOString(),
      acquisition: complete.approved,
    },
  },
  contactDetails,
  latitudeId,
  verification,
  emailVerification,
  password,
  personalDetails,
  otherDetails,
  extraDetails,
  expensesDetails,
  employmentDetails,
  additionalDebtsDetails,
  achDetails,
  confirmCreditLimit: { valid: confirmCreditLimit.valid },
  preferredCreditLimitDetails: { valid: preferredCreditLimitDetails.valid },
  acceptance,
  eIDVConsent,
  eIDV,
  reset: getDefaultState(),
};
